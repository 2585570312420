@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
html {
	font-size: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont;
	font-size: 1rem;
	line-height: 1.5;
	background-color: #fff;
}

* {
	font-family: 'Roboto', Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont;
}

button:disabled {
	opacity: 0.5;
}

